var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[(_vm.loading)?_c('div',{staticClass:"loading-spinner"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e(),_c('div',[_c('h1',[_vm._v("Ajuda")])]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Celular","label-for":"phone"}},[_c('validation-observer',{ref:"phoneValidation",attrs:{"tag":"form"}},[_c('validation-provider',{attrs:{"name":"Celular","vid":"celular","rules":"required|celular"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"phone","options":_vm.options.celular,"raw":false,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Melhor dia e horário","label-for":"dayNhour"}},[_c('validation-observer',{ref:"dayValidation",attrs:{"tag":"form"}},[_c('validation-provider',{attrs:{"name":"Melhor dia e horário","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0 ? 'is-invalid' : '',attrs:{"id":"dayNhour"},model:{value:(_vm.form.bestDayNhour),callback:function ($$v) {_vm.$set(_vm.form, "bestDayNhour", $$v)},expression:"form.bestDayNhour"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Mensagem","label-for":"mensagem"}},[_c('validation-observer',{ref:"msgValidation",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"code",fn:function(){return [_vm._v(" "+_vm._s(_vm.codeCounter)+" ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Mensagem","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{class:errors.length > 0 ? 'is-invalid' : '',style:(errors.length > 0
                  ? 'border-color: #ea5455;'
                  : 'border-color: #0078d4;'),attrs:{"id":"mensagem","maxlength":"2000","state":_vm.form.message.length >= 10,"placeholder":"Digite ao menos 10 caracteres.","rows":"10"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}])}),_c('small',{staticClass:"textarea-counter-value float-right",class:_vm.value.length >= _vm.maxChar ? 'bg-danger' : ''},[_c('span',{staticClass:"char-count"},[_vm._v(_vm._s(_vm.form.message.length))]),_vm._v(" / "+_vm._s(_vm.maxChar)+" ")])],1)],1)],1)],1),_c('b-col',{staticStyle:{"padding-right":"0"},attrs:{"md":"12"}},[_c('div',{staticClass:"text-right mt-2"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Enviar ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }