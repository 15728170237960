<template>
  <b-card>
    <div
      v-if="loading"
      class="loading-spinner"
    >
      <b-spinner variant="primary" />
    </div>
    <div>
      <h1>Ajuda</h1>
    </div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Celular"
          label-for="phone"
        >
          <validation-observer
            ref="phoneValidation"
            tag="form"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Celular"
              vid="celular"
              rules="required|celular"
            >
              <cleave
                id="phone"
                v-model="form.phone"
                class="form-control"
                :options="options.celular"
                :class="errors.length > 0 ? 'is-invalid' : ''"
                :raw="false"
                :state="errors.length > 0 ? false : null"
              />
              <span>{{ errors[0] }}</span>
            </validation-provider>
          </validation-observer>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Melhor dia e horário"
          label-for="dayNhour"
        >
          <validation-observer
            ref="dayValidation"
            tag="form"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Melhor dia e horário"
              rules="required"
            >
              <b-form-input
                id="dayNhour"
                v-model="form.bestDayNhour"
                :class="errors.length > 0 ? 'is-invalid' : ''"
              />
              <span>{{ errors[0] }}</span>
            </validation-provider>
          </validation-observer>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-form-group
          label="Mensagem"
          label-for="mensagem"
        >
          <validation-observer
            ref="msgValidation"
            tag="form"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Mensagem"
              rules="required"
            >
              <b-form-textarea
                id="mensagem"
                v-model="form.message"
                maxlength="2000"
                :state="form.message.length >= 10"
                placeholder="Digite ao menos 10 caracteres."
                :class="errors.length > 0 ? 'is-invalid' : ''"
                :style="
                  errors.length > 0
                    ? 'border-color: #ea5455;'
                    : 'border-color: #0078d4;'
                "
                rows="10"
              />
              <span>{{ errors[0] }}</span>
            </validation-provider>
            <small
              class="textarea-counter-value float-right"
              :class="value.length >= maxChar ? 'bg-danger' : ''"
            >
              <span class="char-count">{{ form.message.length }}</span> /
              {{ maxChar }}
            </small>
            <template #code>
              {{ codeCounter }}
            </template>
          </validation-observer>
        </b-form-group>
      </b-col>
    </b-row>
    <b-col
      md="12"
      style="padding-right: 0"
    >
      <div class="text-right mt-2">
        <b-button
          variant="primary"
          @click="submit()"
        >
          Enviar
        </b-button>
      </div>
    </b-col>
  </b-card>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt"
import {
  BButton,
  BFormGroup,
  BFormInput,
  BCol,
  BRow,
  BFormTextarea,
  BCard,
  BSpinner,
  BCardText
} from "bootstrap-vue"
import Cleave from "vue-cleave-component"
import { required, min } from "@validations"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { getUserData } from "@/auth/utils"
import BCardCode from "@core/components/b-card-code"
import { codeCounter } from "./code"
export default {
  components: {
    // BCardCode,
    // BCardText,
    BCard,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BFormTextarea,
    BButton,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  data() {
    return {
      loading: false,
      required,
      text: "",
      value: "",
      maxChar: 2000,
      codeCounter,
      min,
      form: {
        phone: "",
        bestDayNhour: "",
        message: ""
      },
      options: {
        celular: {
          delimiters: ["(", ")", " ", "-"],
          blocks: [0, 2, 0, 5, 4],
          numericOnly: true
        }
      }
    }
  },
  methods: {
    clearFields() {
      this.form.phone = ""
      this.form.bestDayNhour = ""
      this.form.message = ""
      this.$refs.phoneValidation.reset()
      this.$refs.dayValidation.reset()
      this.$refs.msgValidation.reset()
    },
    async submit() {
      const phoneValid = await this.$refs.phoneValidation.validate()
      const dayValid = await this.$refs.dayValidation.validate()
      const msgValid = await this.$refs.msgValidation.validate()
      const isValid = phoneValid && dayValid && msgValid
      if (isValid) {
        this.loading = true
        const userInfo = getUserData()
        const response = await useJwt
          .enviarAjuda({
            idDoCliente: userInfo.idDoCliente,
            nomeDoUsuario: userInfo.nomeDoUsuario,
            telefone: this.form.phone,
            melhorDiaHorario: this.form.bestDayNhour,
            mensagem: this.form.message
          })
          .then(response => response.data)
          .catch(response => {
            let catchError = Object.assign({}, response)
            this.MensagemErrorEnviarAjuda(
              "danger",
              "top-center",
              catchError.response.data.mensagem
            )
            // this.$toast({
            //   component: ToastificationContent,
            //   position: "top-right",
            //   timeout: 2000,
            //   props: {
            //     title: "Houve um erro ao enviar sua mensagem.",
            //     icon: "CoffeeIcon",
            //     variant: "danger",
            //     text: catchError.response.data.mensagem
            //   }
            // })
          })
        this.loading = false
        if (response.sucesso) {
          this.MensagemEnviarAjuda("success", "top-center")
          // this.$toast({
          //   component: ToastificationContent,
          //   position: "top-right",
          //   timeout: 2000,
          //   props: {
          //     title: "Mensagem Enviada com sucesso!",
          //     icon: "CoffeeIcon",
          //     variant: "success",
          //     text: "Agradecemos o contato."
          //   }
          // })
          this.clearFields()
        } else {
          this.MensagemError("danger", "top-center")
          // this.$toast({
          //   component: ToastificationContent,
          //   position: "top-right",
          //   timeout: 2000,
          //   props: {
          //     title: "Erro",
          //     icon: "ErrorIcon",
          //     variant: "danger",
          //     text:
          //       "Ocorreu um erro ao enviar sua mensagem, por favor, tente novamente"
          //   }
          // })
        }
      }
      this.loading = false
    },
    MensagemErrorEnviarAjuda(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Houve um erro ao enviar sua mensagem.",
            icon: "CoffeeIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemEnviarAjuda(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Mensagem Enviada com sucesso!",
            icon: "CoffeeIcon",
            variant,
            text: "Agradecemos o contato."
          }
        },
        {
          position
        }
      )
    },
    MensagemError(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro",
            icon: "ErrorIcon",
            variant,
            text:
              "Ocorreu um erro ao enviar sua mensagem, por favor, tente novamente"
          }
        },
        {
          position
        }
      )
    }
  }
}
</script>

<style>
.loading-spinner {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(118, 118, 118, 0.3);
}
.spacing {
  padding-bottom: 2rem;
}
.button-send {
  display: flex;
  flex-direction: row-reverse;
}
</style>
